@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #cbde73;
  --color-primary-100: #cbde73;
  --color-primary-200: #cc006b;
  --color-primary-600: #61856b;
  --color-primary-900: #405847;
  /*--color-primary-100: #eee56f;*/
  /*--color-primary-900: #615c1e;*/
}
@font-face {
  font-family: 'BeauRivage';
  src: local('BeauRivage'), url(./assets/BeauRivage-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'BirdsOfParadise';
  src: local('BirdsOfParadise'), url(./assets/BirdsofParadise.ttf) format('truetype');
}


.titlefont {
    font-family: 'BirdsOfParadise', sans-serif, serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 10s ease-in infinite;
  -moz-animation: AnimationName 10s ease-in-out infinite;
  animation: AnimationName 10s ease-out infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}